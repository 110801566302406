<template>
  <div class="st" @click="scrollTop()">
    <img src="../assets/images/icon/common/scroll-top.svg" style="width: 35px;height: 35px" alt="">
  </div>
</template>

<script>
  export default {
    name: "ScrollTopComp",
    methods: {
      scrollTop() {
        document.body.scrollIntoView()
      }
    }
  }
</script>

<style scoped>
  .st {
    width: 50px;
    height: 50px;
    font-size: 40px;
    background-color: var(--scrolltopbg);
    border-radius: 50%;
    position: fixed;
    bottom: 3%;
    right: 25%;
    z-index: 999;
    text-align: center;
    cursor: pointer;
    opacity: .9;

    box-shadow: rgba(0, 0, 0, 0.6) -3px 6px 6px -3px;
  }
  .st i{
    line-height: 50px;
    color: #ffffff;
  }
  @media screen and (max-width:1024px) {
    .st {
      right: 11%;
      bottom: 13%;
      width: 40px;
      height: 40px;
      font-size: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .st i{
      line-height: 40px;
      color: #ffffff;
    }
  }
</style>